<template>
  <a-modal class="add-tag-dialog"
           v-model:visible="innerVisible"
           title="编辑标签组"
           width='600px'
           :confirm-loading="confirmLoading"
           @ok="onSubmit"
           @cancel="$emit('update:visible', false)">
    <a-form ref="formRef"
            :model="tagForm"
            hideRequiredMark
            :labelCol="{span: 4}"
            :wrapperCol="{span: 20}">
      <a-form-item label='标签组名称'
                   name="name"
                   :rules="[
        {
        required: true,
        message: '标签组名不能为空',
        whitespace: true,
        },{
          min: 1,
          max: 15,
          message: '只能输入1-15个字符',
        },
        {
          validator: validatorApi,
        }]">
        <a-input class="add-tag-dialog__tagGroupName"
                 v-model:value.trim="tagForm.name"
                 @change="$refs.formRef.validate()" />
      </a-form-item>

      <a-form-item v-for="(labelName, index) in tagForm.corpTagList"
                   :key="index"
                   :label="index === 0 ? '标签名称' : ' '"
                   :name="['corpTagList', index,  'name']"
                   :rules="[
      {
        required: true,
        message: '标签名不能为空',
        whitespace: true,
      },{
        min: 1,
        max: 15,
        message: '只能输入1-15个字符',
      },
      {
        validator: validatorName,
      },
      ]">
        <a-input v-model:value.trim="labelName.name"
                 placeholder="请输入标签名称"
                 :class="{'not-only': tagForm.corpTagList.length > 1}"
                 @change="$refs.formRef.validate()" />
        <svg-icon v-if="tagForm.corpTagList.length > 1"
                  class="text-icon icon-20"
                  :disabled="tagForm.corpTagList.length === 1"
                  @click="removeDomain(labelName)"
                  type="iconshanchu" />

      </a-form-item>
      <a-form-item :wrapperCol="{span: 20, offset: 4}">
        <a-button class="add-tag-dialog__addButton"
                  type="dashed"
                  style="width: 100%;"
                  @click="addDomain">
          <PlusOutlined />
          添加标签名
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons-vue";
import CorpTag from "@/service/api/corpLag.js";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "CustomerEditTagDialog",
  props: {
    visible: Boolean,
    id: String,
  },
  emits: ["created", "update:visible"],
  components: {
    PlusOutlined,
    SvgIcon,
  },
  data() {
    const validatorApi = _.debounce(async (rule, value) => {
      if (!value) {
        return Promise.reject("标签组名不能为空");
      }

      return Promise.resolve();
    }, 500);

    const validatorName = _.debounce(async (rule, value) => {
      const nameList = _.map(this.tagForm.corpTagList, "name");
      const firstIndex = _.indexOf(nameList, value);
      const lastIndex = _.lastIndexOf(nameList, value);

      if (firstIndex !== lastIndex) {
        return Promise.reject("标签名不能重复");
      }

      return Promise.resolve();
    }, 500);

    return {
      validatorApi,
      validatorName,
      innerVisible: false,
      confirmLoading: false,
      deleteCorpTagList: [],
      tagForm: {},
    };
  },
  watch: {
    visible: function (newVisible) {
      this.innerVisible = newVisible;
      if (newVisible) {
        this.deleteCorpTagList = [];
        this.getGroupTag();
      }
    },
  },
  methods: {
    async getGroupTag() {
      this.tagForm = await CorpTag.getTag({ workWechatTagGroupId: this.id });
      _.forEach(this.tagForm.corpTagList, (tag) => {
        tag.isUpdate = true;
      });
    },
    removeDomain(item) {
      if (item.workWechatThirdAppTagId) {
        this.deleteCorpTagList.push({
          workWechatThirdAppTagId: item.workWechatThirdAppTagId,
        });
      }

      const index = _.indexOf(this.tagForm.corpTagList, item);
      if (index !== -1) {
        this.tagForm.corpTagList.splice(index, 1);
      }
    },
    addDomain() {
      this.tagForm.corpTagList.push({
        name: "",
        key: Date.now(),
      });
    },
    onSubmit() {
      this.$refs.formRef
        .validate()
        .then(async () => {
          let newGroupTag = {
            addCorpThirdAppTagList: [],
            updateCorpThirdAppTagList: [],
          };
          _.forEach(this.tagForm.corpTagList, (tag) => {
            if (tag.isUpdate) {
              newGroupTag.updateCorpThirdAppTagList.push(tag);
            } else {
              newGroupTag.addCorpThirdAppTagList.push({ name: tag.name });
            }
          });
          newGroupTag.deleteCorpThirdAppTagList = this.deleteCorpTagList;
          newGroupTag.workWechatThirdAppTagGroupId =
            this.tagForm.workWechatThirdAppTagGroupId;
          newGroupTag.name = this.tagForm.name;

          this.confirmLoading = true;
          await CorpTag.updateTagGroup(newGroupTag);
          this.$message.success("修改成功");
          this.confirmLoading = false;

          this.$emit("created");
          this.$emit("update:visible", false);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
  },
};
</script>
