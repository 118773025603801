<template>
  <a-form ref="formRef"
          :model="tagForm"
          hideRequiredMark
          :labelCol="{span: 4}"
          :wrapperCol="{span: 20}">
    <a-form-item label='标签组名称'
                 name="name"
                 :rules="[
        {
        required: true,
        message: '标签组名不能为空',
        whitespace: true,
        },{
          min: 1,
          max: 15,
          message: '只能输入1-15个字符',
        },
        {
          validator: validatorApi,
        },
      ]">
      <a-input class="add-tag-dialog__tagGroupName"
               v-model:value.trim="tagForm.name"
               @change="$refs.formRef.validate()" />
    </a-form-item>

    <a-form-item v-for="(labelName, index) in tagForm.corpTagList"
                 :key="labelName.key"
                 :label="index === 0 ? '标签名称' : ' '"
                 :name="['corpTagList', index, 'name']"
                 :rules="[
      {
        required: true,
        message: '标签名不能为空',
        whitespace: true,
      },{
        min: 1,
        max: 15,
        message: '只能输入1-15个字符',
      },
      {
        validator: validatorName,
      },
      ]">
      <a-input v-model:value.trim="labelName.name"
               placeholder="请输入标签名称"
               :class="{'not-only': tagForm.corpTagList.length > 1}"
               @change="$refs.formRef.validate()" />
      <svg-icon v-if="tagForm.corpTagList.length > 1"
                class="text-icon icon-20"
                :disabled="tagForm.corpTagList.length === 1"
                @click="removeDomain(labelName)"
                type="iconshanchu" />
    </a-form-item>
    <a-form-item :wrapperCol="{span: 20, offset: 4}">
      <a-button class="add-tag-dialog__addButton"
                type="dashed"
                style="width: 100%;"
                @click="addDomain">
        <PlusOutlined />
        添加标签名
      </a-button>
    </a-form-item>
    <a-form-item class="add-tag-dialog__confirmBtnList">
      <a-button @click="resetForm">取消</a-button>
      <a-button style="margin-left: 10px"
                type="primary"
                @click="onSubmit"
                :disabled="isSync">
        <SyncOutlined v-if="isSync"
                      :spin="isSync" />保存
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import _ from "lodash";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref } from "vue";
import SvgIcon from "@/components/SvgIcon";

import CorpTag from "@/service/api/corpLag.js";

export default defineComponent({
  name: "CustomerAddTagDialog",
  components: {
    PlusOutlined,
    SyncOutlined,

    SvgIcon,
  },
  emits: ["closeModel", "created"],
  setup(props, context) {
    let checkRes = { ok: false };
    const formRef = ref();
    const tagForm = reactive({
      corpTagList: [],
      name: "",
    });
    const isSync = ref(false);

    const validatorApi = async (rule, value) => {
      if (!value) {
        return Promise.reject("标签组名不能为空");
      }

      if (tagForm.corpTagList.length === 0) {
        // message.error("请新建子标签");
        return Promise.reject("请新建子标签");
      }

      const checkRes = await CorpTag.checkTagGroupName({ name: value });
      if (!checkRes.ok) {
        return Promise.reject("该标签组名已存在");
      }

      return Promise.resolve();
    };

    const validatorName = async (rule, value) => {
      const nameList = _.map(tagForm.corpTagList, "name");
      const firstIndex = _.indexOf(nameList, value);
      const lastIndex = _.lastIndexOf(nameList, value);

      if (firstIndex !== lastIndex) {
        return Promise.reject("标签名不能重复");
      }

      return Promise.resolve();
    };

    const onSubmit = async () => {
      formRef.value.validate().then(async () => {
        isSync.value = true;
        const newTagGroup = await CorpTag.createTagGroup(tagForm);
        isSync.value = false;
        context.emit("created", newTagGroup);
        context.emit("closeModel");
        formRef.value.resetFields();
        tagForm.corpTagList = [];
      });
    };

    const resetForm = () => {
      context.emit("closeModel");
    };

    const removeDomain = (item) => {
      let index = tagForm.corpTagList.indexOf(item);

      if (index !== -1) {
        tagForm.corpTagList.splice(index, 1);
      }
    };

    const addDomain = () => {
      tagForm.corpTagList.push({
        name: "",
        key: Date.now(),
      });
    };

    return {
      formRef,
      isSync,
      tagForm,
      resetForm,
      removeDomain,
      addDomain,
      validatorApi,
      validatorName,
      checkRes,
      onSubmit,
    };
  },
});
</script>