<template>
  <div class="tag-manage">
    <a-button type="primary"
              @click="addModalVisible = true">
      <PlusOutlined />新建标签组
    </a-button>

    <a-button class="tag-manage__wechat"
              :disabled="isSync"
              @click="asyncWorkWechatTag">
      <SyncOutlined :spin="isSync" />同步微信标签
    </a-button>

    <a-modal v-model:visible="addModalVisible"
             class="add-tag-dialog"
             title="新建标签组"
             width='600px'
             :footer='null'>
      <add-tag-dialog @closeModel="addModalVisible = false"
                      @created="getList()" />
    </a-modal>

    <a-divider />

    <div class="tag-manage__group"
         v-for="(groupTagList, index) in corpGroupTagList"
         :key="groupTagList.workWechatThirdAppTagGroupId">
      <p>{{groupTagList.name}}：</p>
      <template v-for="tag in groupTagList.corpTagList"
                :key="tag.id">
        <a-tag>
          {{ tag.name }}
        </a-tag>
      </template>
      <a-input v-if="inputVisible[index]"
               v-model:value="inputValue"
               ref="inputRef"
               type="text"
               size="small"
               style="width: 78px;"
               @blur="handleInputConfirm(groupTagList.workWechatThirdAppTagGroupId)"
               @keyup.enter="handleInputConfirm(groupTagList.workWechatThirdAppTagGroupId)" />

      <a-tag v-else
             class="tag-manage__addTag"
             @click="showInput(index)">
        <PlusOutlined />
        添加
      </a-tag>

      <a class="tag-manage__edit"
         @click="editModalVisible = true; groupId = groupTagList.workWechatThirdAppTagGroupId">
        <svg-icon class="mr-8 text-16"
                  type="iconbianji" />
        编辑
      </a>

      <a-popconfirm class="tag-manage__delete"
                    trigger="click"
                    :overlayStyle="{
                      'min-width': 'fit-content'
                    }"
                    title="标签组删除后不可恢复，确认删除？"
                    @confirm="showDeleteConfirm(groupTagList.workWechatThirdAppTagGroupId)">
        <template #icon>
          <ExclamationCircleOutlined />
        </template>
        <svg-icon class="text-12"
                  type="iconshanchu" />
        删除
      </a-popconfirm>
      <a-divider />
    </div>

    <edit-tag-dialog v-model:visible="editModalVisible"
                     :id="groupId"
                     @created="getList()" />
  </div>
</template>

<script>
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Tag, Modal, Popconfirm } from "ant-design-vue";
// import { createVNode } from "vue";
import { message } from "ant-design-vue";
import { SyncOutlined } from "@ant-design/icons-vue";
import { ref, nextTick } from "vue";
import AddTagDialog from "./CustomerAddTagDialog.vue";
import EditTagDialog from "./CustomerEditTagDialog.vue";
import CorpTag from "@/service/api/corpLag.js";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "CustomerTag",
  components: {
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,

    [Tag.name]: Tag,
    [Modal.name]: Modal,
    [Popconfirm.name]: Popconfirm,

    AddTagDialog,
    EditTagDialog,
    SvgIcon,
  },
  setup() {
    const inputRef = ref();
    const corpGroupTagList = ref([]);
    const name = ref("");
    const inputVisible = ref([]);
    const inputValue = ref("");
    // const state = reactive({
    //   name: "",

    //   inputVisible: [],
    //   inputValue: "",
    // });
    const getList = async () => {
      corpGroupTagList.value = await CorpTag.getList();
    };

    //点击添加显示input
    const showInput = (index) => {
      inputVisible.value[index] = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };
    //添加标签
    const clearInputTag = () => {
      inputVisible.value = [];
      inputValue.value = "";
    };
    const handleInputConfirm = async (workWechatThirdAppTagGroupId) => {
      const name = inputValue.value;
      if (!name) {
        clearInputTag();
        return;
      }

      if (name.length > 15) {
        message.error("标签名过长，请重新输入");
        clearInputTag();
        return;
      }
      console.log(workWechatThirdAppTagGroupId);
      await CorpTag.createTag({
        name,
        workWechatThirdAppTagGroupId,
      });

      message.success("添加成功");
      await getList();

      clearInputTag();
    };
    //删除标签组接口
    const showDeleteConfirm = async (workWechatThirdAppTagGroupId) => {
      // Modal.confirm({
      //   title: "标签组删除后不可恢复，确认删除？",
      //   icon: <ExclamationCircleOutlined />,
      //   okText: "确定",
      //   okType: "primary",
      //   cancelText: "取消",
      //   async onOk() {
      //     await CorpTag.deleteTagGroup({ workWechatThirdAppTagGroupId: id });
      //     message.success("删除成功");
      //     await getList();
      //   },
      // });
      await CorpTag.deleteTagGroup({ workWechatThirdAppTagGroupId });
      message.success("删除成功");
      await getList();
    };

    return {
      getList,
      corpGroupTagList,
      inputVisible,
      inputValue,
      name,

      showInput,
      handleInputConfirm,
      showDeleteConfirm,
      inputRef,
    };
  },

  data() {
    return {
      addModalVisible: false,
      editModalVisible: false,
      groupId: "",
      isSync: false,
    };
  },

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    async asyncWorkWechatTag() {
      this.isSync = true;
      await CorpTag.asyncWorkWechatTag();
      this.getList();
      this.isSync = false;
    },
  },
};
</script>
<style scoped></style>;
